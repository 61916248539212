import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Toast, Form } from 'react-bootstrap';
import './css/ActivityTable.css'; // Ensure the correct path
import { MdNetworkWifi1Bar } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoAddCircle } from "react-icons/io5";
import { MdCancelPresentation } from "react-icons/md";
import { AiFillDashboard, AiOutlineSchedule, AiOutlineUser, AiOutlineHome, AiOutlineTool, AiOutlineApi } from 'react-icons/ai';
import { GiTomato } from "react-icons/gi";
import Farm_Crop_Variety from './Farm_Crop_Variety'
const ActivityTable = () => {
  const [activities, setActivities] = useState([]);
  const [editingActivityId, setEditingActivityId] = useState(null);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [errorToast, setErrorToast] = useState(null);

  useEffect(() => {
    fetchActivities();
  }, []);

const handleAddNewRecord = () => {
  setIsNewRecord(true);
  setEditingActivityId('new'); // Use a unique identifier for the new record state
  setEditFormData({ // Reset or set default values for the new record form
    Farm_Crop_ID:'',
    Farm_Crop_Name:""
  });
};

  const fetchActivities = () => {
    setLoading(true);
    fetch('https://server.datasealsoftware.com/api/Farm_Crop')
      .then(response => response.json())
      .then(data => {
        setActivities(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
        displayErrorToast(
          <>
            <MdNetworkWifi1Bar size={15} style={{ color: '#163020' }} />
            Network error fetching data from the server
          </>
        );
        setLoading(false);
      });
  };

  const displayErrorToast = (content) => {
    setErrorToast(content);
    setTimeout(() => {
      setErrorToast(null);
    }, 20000);
  };

  const handleEditClick = (activity) => {
    setIsNewRecord(false);
    setEditingActivityId(activity.Farm_Crop_ID);
    setEditFormData({
      Farm_Crop_Name: activity.Farm_Crop_Name
    });
  };

  const handleCancelClick = () => {
    setEditingActivityId(null);
    setIsNewRecord(false);
  };

  const handleSaveClick = (event) => {
    event.preventDefault();
  
    const method = isNewRecord ? 'POST' : 'PUT';
    const url = `https://server.datasealsoftware.com/api/Farm_Crop${isNewRecord ? '' : `/${editingActivityId}`}`;
  
    fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...editFormData,
        Farm_Crop_ID: isNewRecord ? undefined : editingActivityId,
      }),
    })
      .then(response => response.json()) // Convert the response to JSON
      .then(data => {
        if (data.error) {
          // Display error message from API
          displayErrorToast(data.error); // Assuming displayErrorToast can now handle general messages
        } else {
          // Display success message from API
          displayErrorToast(data.message); // Using the same function for success messages for simplicity
        }
        fetchActivities(); // Refresh the list of activities
        setEditingActivityId(null);
        setIsNewRecord(false);
      })
      .catch(error => {
        console.error("Error saving activity: ", error);
        displayErrorToast("Error connecting to the server");
      });
  };
  const handleDelete = (activityId) => {
    fetch(`https://server.datasealsoftware.com/api/Farm_Crop/${activityId}`, {
      method: 'DELETE',
    })
      .then(response => response.json()) // Convert the response to JSON to access the message
      .then(data => {
        if (data.error) {
          // If there's an error returned by the API, display it
          displayErrorToast(data.error);
        } else {
          // If delete was successful, display a success message
          displayErrorToast("Farm crop deleted successfully");
          fetchActivities(); // Refresh the list of activities
        }
      })
      .catch(error => {
        console.error("Error deleting Employee : ", error);
        displayErrorToast("Error deleting Employee on the server");
      });
  };
  

  const handleEditFormChange = (event) => {
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    setEditFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: fieldValue,
    }));
  };


  return (
    <Container style={{ marginTop: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <h4 style={{ color: '#12372a' }}>Farm Crops</h4><GiTomato size={10} style={{ marginRight: '10px',color:'red' }} />
        <a href="#!" onClick={handleAddNewRecord}  style={{ display: 'flex', alignItems: 'center', textDecoration: 'none',color:'black',width:'auto' }}>

        <div
  style={{
    backgroundColor: 'hsla(0, 0%, 87%, 0.625)',
    borderRadius: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '1px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', // Adding shadow
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }}
>
  <IoAddCircle size={20}/> Add new record(Crops)
</div>
</a>
        {isLoading && (
          <div>
            <Spinner animation="grow" size="sm" role="status" variant="success">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Spinner animation="grow" role="status" variant="danger">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>

      {/* Error Toast */}
      <Toast
        show={!!errorToast}
        onClose={() => setErrorToast(null)}
        delay={5000}
        autohide
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 1 }}
      >
        <Toast.Header closebutton={false} style={{backgroundColor:"black",color:'white'}}>
          <strong className="me-auto" >Server Error</strong>
        </Toast.Header>
        <Toast.Body>{errorToast}</Toast.Body>
      </Toast>
      <Table striped="columns" bordered size="sm" Responsive hover className="excel-like-table" variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>Crop Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '14px', lineHeight: '10px' }}>
        {isNewRecord && (
    <tr>
      <td>#</td>
      
      <td>
        <Form.Control
        placeholder='Cabbage'
          type="text"
          name="Farm_Crop_Name"
          value={editFormData.Farm_Crop_Name}
          onChange={handleEditFormChange}
        />
      </td>
     
      
      {/* Action buttons for saving or canceling the new record */}
      <td>
        <button className="btn-extra-sm btn-primary" variant="success" onClick={handleSaveClick} >
          Save New
        </button>
        <button className="btn-extra-sm btn-secondary" variant="secondary" onClick={handleCancelClick}>
        Cancel<MdCancelPresentation  size={15} />
        </button>
      </td>
    </tr>
  )}
          {activities.map((activity, index) => (
            <tr key={activity.Farm_Crop_ID}>
                    <td>{index + 1}</td> {/* This will display an incrementing number */}

              {editingActivityId === activity.Farm_Crop_ID ? (
                // If currently editing, show form fields
                <>
                 <td>
        <Form.Control
          type="text"
          placeholder='Cabbage'
          name="Farm_Crop_Name"
          value={editFormData.Farm_Crop_Name}
          onChange={handleEditFormChange}
        />
      </td>

                </>
              ) : (
                // If not editing, show text
                <>
      
                  <td>{activity.Farm_Crop_Name}</td>
                </>
              )}
              <td>
                            {editingActivityId === activity.Farm_Crop_ID ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <button className="btn-extra-sm btn-primary" onClick={(event) => handleSaveClick(event, activity.Farm_Crop_ID)}>
                    Save
                  </button>
                  <button className="btn-extra-sm btn-secondary" onClick={handleCancelClick}>
                    Cancel
                  </button>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <button className="btn-extra-sm btn-primary" onClick={() => handleEditClick(activity)}>
                    Edit
                  </button>
                  <button className="btn-extra-sm btn-danger" onClick={() => handleDelete(activity.Farm_Crop_ID)}>
                    <RiDeleteBin6Line size={15} />
                  </button> */}
                </div>
              )}


              </td>
            </tr>
          ))}
        </tbody>
        
      </Table>
      <Farm_Crop_Variety />
          </Container>
  );
};

export default ActivityTable;
