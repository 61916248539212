import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap'; // Ensure you've imported Spinner from 'react-bootstrap'

const WeatherDisplay = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get('https://api.weatherapi.com/v1/forecast.json', {
          params: {
            key: 'dec8659fad4e41ffa50205849211212', // Use your actual API key
            q: '0.651713, 36.670509',
            days: 2, // Fetching today's weather and the following day's forecast
          },
        });
        setWeatherData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "#EEF0E5", marginLeft: '15%', fontSize: '18px' }}>
        <Spinner animation="border" variant="light" />
      </div>
    );
  }

  if (error) {
    return <div>Error fetching weather data.</div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
      {weatherData && (
        <>
          <div style={{ lineHeight: '1', marginRight: '5px', color: '#EEF0E5' }}>
            <h4 style={{ fontSize: '12px', margin: '0', textDecoration: 'underline' }}>Weather at Angaa Farm</h4>
            <div style={{ marginLeft: '10px' }}>
              <p style={{ fontSize: '10px', margin: '2px 0' }}>Temp: {weatherData.current.temp_c-5}°C</p>
              <p style={{ fontSize: '10px', margin: '2px 0' }}>Humidity: {weatherData.current.humidity+17}%</p>
              <p style={{ fontSize: '10px', margin: '2px 0' }}>Wind: {(weatherData.current.wind_kph-10).toFixed(1)} kph ({weatherData.current.wind_dir})</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#EEF0E5' }}>
            <img src={`https:${weatherData.current.condition.icon}`} alt="Weather icon" style={{ width: '30px', height: '30px' }} />
            <p style={{ fontSize: '10px', backgroundColor: '#EEF0E5', color: 'black', borderRadius: '30px', paddingLeft: '4px', paddingRight: '4px' }}>Condition: {weatherData.current.condition.text}</p>
          </div>

          <div style={{ height: '40px', width: '1px', backgroundColor: 'red', margin: '0 25px' }}></div> {/* Divider */}

          {weatherData.forecast.forecastday[1] && (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '10px', color: '#EEF0E5' }}>
                <h4 style={{ fontSize: '12px', margin: '0', textDecoration: 'underline' }}>Tomorrow's Forecast</h4>
                <p style={{ fontSize: '10px', margin: '2px 0' }}>Max Temp: {(weatherData.forecast.forecastday[1].day.maxtemp_c-5.0).toFixed(1)}°C</p>
                <p style={{ fontSize: '10px', margin: '2px 0' }}>Min Temp: {(weatherData.forecast.forecastday[1].day.mintemp_c-5).toFixed(1)}°C</p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#EEF0E5' }}>
                <img src={`https:${weatherData.forecast.forecastday[1].day.condition.icon}`} alt="Tomorrow's Weather icon" style={{ width: '30px', height: '30px' }} />
                <p style={{ fontSize: '10px', backgroundColor: '#EEF0E5', color: 'black', borderRadius: '30px', paddingLeft: '4px', paddingRight: '4px' }}>Condition: {weatherData.forecast.forecastday[1].day.condition.text}</p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WeatherDisplay;
