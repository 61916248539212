import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Toast, Form } from 'react-bootstrap';
import './css/ActivityTable.css';
import { MdNetworkWifi1Bar } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoAddCircle } from "react-icons/io5";
import { MdCancelPresentation } from "react-icons/md";
import { RiPlantFill } from 'react-icons/ri';
import { GiTomato } from "react-icons/gi";
import DateTimePicker from 'react-datetime-picker';

const ActivityTable = () => {
  const [activities, setActivities] = useState([]);
  const [detailsFarmActivity, setDetailsFarmActivity] = useState([]);
  const [detailsFarmBlock, setDetailsFarmBlock] = useState([]);
  const [detailsFarmSection, setDetailsFarmSection] = useState([]);
  const [detailsFarmEmployee, setDetailsFarmEmployee] = useState([]);

  const [editingActivityId, setEditingActivityId] = useState(null);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [errorToast, setErrorToast] = useState(null);

  useEffect(() => {
    fetchActivities();
    farmActivityFetch();
    farmBlockFetch();
    farmSectionFetch();
    farmEmployeeFetch();
  }, []);

  const fetchActivities = () => {
    setLoading(true);
    fetch('https://server.datasealsoftware.com/api/Bed_Raking_Activity')
      .then(response => response.json())
      .then(data => {
        setActivities(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
        displayErrorToast(
          <>
            <MdNetworkWifi1Bar size={15} style={{ color: '#163020' }} />
            Network error fetching data from the server
          </>
        );
        setLoading(false);
      });
  };

  const farmActivityFetch = () => {
    fetch('https://server.datasealsoftware.com/api/Farm_Activity')
      .then(response => response.json())
      .then(data => {
        setDetailsFarmActivity(data);
      });
  };

  const farmBlockFetch = () => {
    fetch('https://server.datasealsoftware.com/api/Farm_Block')
      .then(response => response.json())
      .then(data => {
        setDetailsFarmBlock(data);
      });
  };

  const farmSectionFetch = () => {
    fetch('https://server.datasealsoftware.com/api/Farm_Section')
      .then(response => response.json())
      .then(data => {
        setDetailsFarmSection(data);
      });
  };

  const farmEmployeeFetch = () => {
    fetch('https://server.datasealsoftware.com/api/Farm_Employee')
      .then(response => response.json())
      .then(data => {
        setDetailsFarmEmployee(data);
      });
  };

  const displayErrorToast = (content) => {
    setErrorToast(content);
    setTimeout(() => {
      setErrorToast(null);
    }, 20000);
  };

  const handleAddNewRecord = () => {
    setIsNewRecord(true);
    setEditingActivityId('new');
    setEditFormData({
      Bed_Raking_Activity_ID: '',
      Farm_Activity_ID: "",
      Farm_Block_ID: "",
      Farm_Section_ID: "",
      Farm_Employee_ID: "",
      Activity_Start_Time: "",
      Activity_End_Time: ""
    });
  };

  const handleEditClick = (activity) => {
    setIsNewRecord(false);
    setEditingActivityId(activity.Bed_Raking_Activity_ID);
    setEditFormData({
      Farm_Activity_ID: activity.Farm_Activity_ID,
      Farm_Block_ID: activity.Farm_Block_ID,
      Farm_Section_ID: activity.Farm_Section_ID,
      Farm_Employee_ID: activity.Farm_Employee_ID,
      Activity_Start_Time: activity.Activity_Start_Time,
      Activity_End_Time: activity.Activity_End_Time
    });
  };

  const handleCancelClick = () => {
    setEditingActivityId(null);
    setIsNewRecord(false);
  };

  const handleSaveClick = (event, activityId) => {
    event.preventDefault();

    const method = isNewRecord ? 'POST' : 'PUT';
    const url = isNewRecord ?
      'https://server.datasealsoftware.com/api/Bed_Raking_Activity' :
      `https://server.datasealsoftware.com/api/Bed_Raking_Activity/${editingActivityId}`;

    fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...editFormData,
        Bed_Raking_Activity_ID: isNewRecord ? undefined : editingActivityId,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          displayErrorToast(data.error);
        } else {
          displayErrorToast(data.message);
        }
        fetchActivities();
        setEditingActivityId(null);
        setIsNewRecord(false);
      })
      .catch(error => {
        console.error("Error saving activity: ", error);
        displayErrorToast("Error connecting to the server");
      });
  };

  const handleDelete = (activityId) => {
    fetch(`https://server.datasealsoftware.com/api/Bed_Raking_Activity/${activityId}`, {
      method: 'DELETE',
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          displayErrorToast(data.error);
        } else {
          displayErrorToast("Farm Block deleted successfully");
          fetchActivities();
        }
      })
      .catch(error => {
        console.error("Error deleting Block: ", error);
        displayErrorToast("Error deleting activity on the server");
      });
  };

  const handleEditFormChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setEditFormData(prevFormData => ({
      ...prevFormData,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <Container style={{ marginTop: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <h4 style={{ color: '#12372a' }}>Farm Raking Activities</h4>
        <GiTomato size={10} style={{ marginRight: '10px', color: 'red' }} />
        <a href="#!" onClick={handleAddNewRecord} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black', width: 'auto' }}>
          <div
            style={{
              backgroundColor: 'hsla(0, 0%, 87%, 0.625)',
              borderRadius: '10px',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingBottom: '1px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <IoAddCircle size={20} /> Add new Activity(Raking)
          </div>
        </a>
        {isLoading && (
          <div>
            <Spinner animation="grow" size="sm" role="status" variant="success">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Spinner animation="grow" role="status" variant="danger">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>

      <Toast
        show={!!errorToast}
        onClose={() => setErrorToast(null)}
        delay={5000}
        autohide
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 1 }}
      >
        <Toast.Header closebutton={false} style={{ backgroundColor: "black", color: 'white' }}>
          <strong className="me-auto" >Server Error</strong>
        </Toast.Header>
        <Toast.Body>{errorToast}</Toast.Body>
      </Toast>

      <Table striped bordered size="sm" responsive hover className="excel-like-table" variant="dark">
        <thead>
          <tr>
            <th>#</th>
            <th>Farm Activity</th>
            <th>Farm Block</th>
            <th>Farm Section</th>
            <th>Farm Employee</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '14px', lineHeight: '10px' }}>
          {isNewRecord && 
          (
            <tr>
              <td>#</td>
              <td>
                <Form.Select
                  aria-label="Default select example"
                  name="Farm_Activity_ID"
                  value={editFormData.Farm_Activity_ID}
                  onChange={handleEditFormChange}
                >
                  <option value="">Select Crop</option>
                  {detailsFarmActivity.map(detail => (
                    <option key={detail.Farm_Activity_ID} value={detail.Farm_Activity_ID}>
                      {detail.Farm_Activity_Name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  aria-label="Default select example"
                  name="Farm_Block_ID"
                  value={editFormData.Farm_Block_ID}
                  onChange={handleEditFormChange}
                >
                  <option value="">Select Block</option>
                  {detailsFarmBlock.map(detail => (
                    <option key={detail.Farm_Block_ID} value={detail.Farm_Block_ID}>
                      {detail.Block_Name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  aria-label="Default select example"
                  name="Farm_Section_ID"
                  value={editFormData.Farm_Section_ID}
                  onChange={handleEditFormChange}
                >
                  <option value="">Select Section</option>
                  {detailsFarmSection.map(detail => (
                    <option key={detail.Farm_Section_ID} value={detail.Farm_Section_ID}>
                      {detail.Farm_Section}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  aria-label="Default select example"
                  name="Farm_Employee_ID"
                  value={editFormData.Farm_Employee_ID}
                  onChange={handleEditFormChange}
                >
                  <option value="">Select Employee</option>
                  {detailsFarmEmployee.map(detail => (
                    <option key={detail.Farm_Employee_ID} value={detail.Farm_Employee_ID}>
                      {detail.Farm_Employee_Name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
        <Form.Control
          type="datetime-local"
          name="Activity_Start_Time"
          value={editFormData.Activity_Start_Time}
          onChange={handleEditFormChange}
        />
      </td>


      <td>
        <Form.Control
          type="datetime-local"
          name="Activity_End_Time"
          value={editFormData.Activity_End_Time}
          onChange={handleEditFormChange}
        />
      </td>
              <td>
                <button className="btn-extra-sm btn-primary" onClick={handleSaveClick}>Save New</button>
                <button className="btn-extra-sm btn-secondary" onClick={handleCancelClick}>Cancel<MdCancelPresentation size={15} /></button>
              </td>
            </tr>
          )}
          {activities.map((activity, index) => (
            <tr key={activity.Bed_Raking_Activity_ID}>
              <td>{index + 1}</td>
              {editingActivityId === activity.Bed_Raking_Activity_ID ? (
                <>
                  {/* Display form fields for editing */}
                  <td>
                    <Form.Select
                      aria-label="Default select example"
                      name="Farm_Activity_ID"
                      value={editFormData.Farm_Activity_ID}
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Crop</option>
                      {detailsFarmActivity.map(detail => (
                        <option key={detail.Farm_Activity_ID} value={detail.Farm_Activity_ID}>
                          {detail.Farm_Activity_Name}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      aria-label="Default select example"
                      name="Farm_Block_ID"
                      value={editFormData.Farm_Block_ID}
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Block</option>
                      {detailsFarmBlock.map(detail => (
                        <option key={detail.Farm_Block_ID} value={detail.Farm_Block_ID}>
                          {detail.Block_Name}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      aria-label="Default select example"
                      name="Farm_Section_ID"
                      value={editFormData.Farm_Section_ID}
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Section</option>
                      {detailsFarmSection.map(detail => (
                        <option key={detail.Farm_Section_ID} value={detail.Farm_Section_ID}>
                          {detail.Farm_Section}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      aria-label="Default select example"
                      name="Farm_Employee_ID"
                      value={editFormData.Farm_Employee_ID}
                      onChange={handleEditFormChange}
                    >
                      <option value="">Select Employee</option>
                      {detailsFarmEmployee.map(detail => (
                        <option key={detail.Farm_Employee_ID} value={detail.Farm_Employee_ID}>
                          {detail.Farm_Employee_Name}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  {/* <td>
                    <DateTimePicker
                      onChange={(value) => setEditFormData(prevFormData => ({...prevFormData, Activity_Start_Time: value}))}
                      value={editFormData.Activity_Start_Time}
                    />
                  </td> */}

                  <td>
        <Form.Control
          type="datetime-local"
          name="Activity_Start_Time"
          value={editFormData.Activity_Start_Time}
          onChange={handleEditFormChange}
        />
      </td>

      <td>
        <Form.Control
          type="datetime-local"
          name="Activity_End_Time"
          value={editFormData.Activity_End_Time}
          onChange={handleEditFormChange}
        />
      </td>
                  {/* <td>
                    <DateTimePicker
                      onChange={(value) => setEditFormData(prevFormData => ({...prevFormData, Activity_End_Time: value}))}
                      value={editFormData.Activity_End_Time}
                    />
                  </td> */}
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <button className="btn-extra-sm btn-primary" onClick={(event) => handleSaveClick(event, activity.Bed_Raking_Activity_ID)}>Save</button>
                      <button className="btn-extra-sm btn-secondary" onClick={handleCancelClick}>Cancel</button>
                    </div>
                  </td>
                </>
              ) : (
                <>
                  {/* Display activity details */}
                  <td>
                    {detailsFarmActivity.find(act => act.Farm_Activity_ID === activity.Farm_Activity_ID)?.Farm_Activity_Name || 'Unknown Activity'}
                  </td>
                  <td>
                    {detailsFarmBlock.find(act => act.Farm_Block_ID === activity.Farm_Block_ID)?.Block_Name || 'Unknown Block'}
                  </td>
                  <td>
                    {detailsFarmSection.find(act => act.Farm_Section_ID === activity.Farm_Section_ID)?.Farm_Section || 'Unknown Section'}
                  </td>
                  <td>
                    {detailsFarmEmployee.find(act => act.Farm_Employee_ID === activity.Farm_Employee_ID)?.Farm_Employee_Name || 'Unknown Employee'}
                  </td>
                  <td>{new Date(activity.Activity_Start_Time).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}</td>

                  <td>{new Date(activity.Activity_End_Time).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}</td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* <button className="btn-extra-sm btn-primary" onClick={() => handleEditClick(activity)}>Edit</button>
                      <button className="btn-extra-sm btn-danger" onClick={() => handleDelete(activity.Bed_Raking_Activity_ID)}>
                        <RiDeleteBin6Line size={15} />
                      </button> */}
                    </div>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ActivityTable;
