import React from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AiFillDashboard, AiOutlineSchedule, AiOutlineUser, AiOutlineHome, AiOutlineTool, AiOutlineApi } from 'react-icons/ai';
import { RiPlantFill } from 'react-icons/ri';
import { GiSeedling } from "react-icons/gi";
import { GiFertilizerBag } from "react-icons/gi";
import { GiFarmTractor } from "react-icons/gi";
import Leaf from './Images/leaf.png';
import { GiTomato } from "react-icons/gi";
import { LuActivity } from "react-icons/lu";
const Sidebar = () => {
  // Access the location object to get the current URL path
  const location = useLocation();

  return (
    <>
      {/* Container for the sidebar with background color, padding, and box shadow */}
      
      <div style={{ backgroundColor: '#B6C4B6', padding: '15px', borderRadius: '0px 0px 30px 0', boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)' ,width:'100%'}}>
        {/* Sidebar heading */}
        <h5 style={{fontWeight: 'bold', color: 'black', marginBottom: '10px'}}>Angaa Farm Management </h5>
        {/* Navigation links */}
        <Nav defaultActiveKey="/home" className="flex-column">
  {/* Link for Farm Management */}
      {/* Separator */}
      <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>
  <Nav.Link href="/Farm_Detail" style={{ color: location.pathname === '/Farm_Detail' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
    <AiOutlineHome size={27} style={{ marginRight: '10px' }} />
    Farm Details
  </Nav.Link>
    {/* Separator */}
    <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>

{/* Link for Crops Management */}
<Nav.Link href="/Farm_Block" style={{ color: location.pathname === '/Farm_Block' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
  <RiPlantFill size={27} style={{ marginRight: '10px' }} />
  Farm Blocks
</Nav.Link>

    {/* Separator */}
    <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>

{/* Link for Crops Management */}
<Nav.Link href="/Farm_Section" style={{ color: location.pathname === '/Farm_Section' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
  <GiSeedling size={27} style={{ marginRight: '10px' }} />
  Farm Section
</Nav.Link>

    {/* Separator */}
    <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>
  <Nav.Link href="/Farm_Activity" style={{ color: location.pathname === '/Farm_Activity' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
    <LuActivity size={27} style={{ marginRight: '10px' }} />
    Farm Activities
  </Nav.Link>

    {/* Separator */}
    <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>
  <Nav.Link href="/Tools" style={{ color: location.pathname === '/Tools' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
    <GiFarmTractor size={27} style={{ marginRight: '10px' }} />
    Agri Tools & Gear
  </Nav.Link>



  {/* Separator */}
  <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>

  {/* Link for Fertilizer Management */}
  <Nav.Link href="/Farm_Employee" style={{ color: location.pathname === '/Farm_Employee' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
    <AiOutlineUser size={27} style={{ marginRight: '10px' }} />
    Farm Employees
  </Nav.Link>

  
  {/* Separator */}
  <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>

  {/* Link for Employee Management */}
  <Nav.Link href="/Farm_Crop" style={{ color: location.pathname === '/Farm_Crop' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
  <GiTomato size={27} style={{ marginRight: '10px' }} />
  Farm Crop
  </Nav.Link>



  {/* Separator */}
  <div style={{ height: '1px', backgroundColor: '#EEF0E5', margin: '10px 0' }}></div>

  {/* Link for Pest/Disease Management */}
  <Nav.Link href="/Farm_Fertililizer_Type" style={{ color: location.pathname === '/Farm_Fertililizer_Type' ? 'red' : '#333', display: 'flex', alignItems: 'center', marginBottom: '10px', textDecoration: 'none' }}>
    <GiFertilizerBag  size={27} style={{ marginRight: '10px' }} />
    Fertililizer
  </Nav.Link>
</Nav>


      </div>
    </>
  );
}

export default Sidebar;
