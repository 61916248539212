import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { BsShieldExclamation } from "react-icons/bs";



const RegisterSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .matches(
      /@angaafarm\.com$/,
      "Email must end with '@angaafarm.com'"
    )
    .required('Email Required'),
  password: Yup.string().required('Password Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirmation Password Required'),
});


const Register = () => {
  const navigate = useNavigate();

  const theme = {
    darkGreen: "#c74f43",
    mediumGreen: "#FBFADA",
    lightGreen: "#ADBC9F",
    offWhite: "#436850",
    font: {
      small: "0.8rem",
      medium: "0.9rem",
      large: "1.0rem",
    },
  };

  const [alertInfo, setAlertInfo] = useState({ variant: '', message: '', show: false });

  const showAlert = (variant, message) => {
    setAlertInfo({ variant, message, show: true });
    setTimeout(() => setAlertInfo({ ...alertInfo, show: false }), 5000); // Hide the alert after 5 seconds
  };

  return (
    <Container style={{ marginTop: '100px', maxWidth: '400px' }}>
         <h2 style={{
            color: '#d1554b',
            marginBottom: '30px',
            padding: '10px',
          fontWeight:'bold',
            textAlign: 'center',
          }}>ANGAA MANAGEMENT </h2>


      <Row className="justify-content-center">
        <Col style={{
  backgroundColor: 'rgba(97, 147, 111, 0.5)', // Semi-transparent version of #61936f
  padding: '40px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(50px)', // Apply a blur effect
  webkitBackdropFilter: 'blur(10px)', // For Safari
}}>
          <h2 style={{
            color: 'white',
            marginBottom: '30px',
            padding: '10px',
            fontSize: theme.font.large,
            backgroundColor: theme.darkGreen,
            borderRadius: '10px',
            textAlign: 'center',
          }}>Agronomist Register</h2>
          {alertInfo.show && <Alert variant={alertInfo.variant}>{alertInfo.message}</Alert>}
          <Formik
            initialValues={{ email: '', password: '', confirmPassword: '' }}
            validationSchema={RegisterSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const { confirmPassword, ...data } = values; // Exclude confirmPassword from the data sent to the server
              axios.post('https://pm.angaafarm.com/api/register', data)
                .then(response => {
                  showAlert('success', 'Registration successful! Redirecting to login...');
                  setTimeout(() => navigate('/login'), 2000); // Redirect after showing the message
                  resetForm();
                })
                .catch(error => {
                  showAlert('danger', 'Registration failed. Please try again.');
                  console.error('Error making request:', error);
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ isSubmitting }) => (
              <Form>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" placeholder="john@angaafarm.com" className="form-control" />
                  <ErrorMessage name="email" component="div" className="text-danger" />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field type="password" name="password" placeholder="*****" className="form-control" />
                  <ErrorMessage name="password" component="div" className="text-danger" />
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <Field type="password" name="confirmPassword"  placeholder="*****" className="form-control" />
                  <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                </div>

      


                {/* Form fields and buttons styled similarly to the login form */}
                {/* Insert form fields here with similar styling */}
                <Button variant="primary" type="submit" disabled={isSubmitting} style={{ 
                  backgroundColor: theme.darkGreen, 
                  borderColor: theme.darkGreen,
                  ':hover': { backgroundColor: theme.mediumGreen },
                  fontSize: theme.font.medium,
                  marginTop: '20px',
                }}>
                  Sign Up
                </Button>
                <div className="mt-4" style={{ textAlign: 'center', fontSize: theme.font.small }}>
                  <Link to="/login" style={{ color: theme.darkGreen }}>Login</Link>
                  {' | '}
                  <Link to="/password-reset" style={{ color: theme.darkGreen }}>Forgot Password?</Link>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <div style={{marginTop:"66px",color:"#c74f43"}}>
      Login access is required to access content of this portal | If having issues with login in consult the admin  <BsShieldExclamation/>

      </div>
    </Container>
  );
};

export default Register;

