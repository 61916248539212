import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Editor from "@monaco-editor/react"; // Import Monaco Editor
import './QueryEditor.css'; // Ensure you have a QueryEditor.css file in the same directory
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import { Modal } from 'react-bootstrap';
import Column from './Columns'
import { SiMicrosoftexcel } from "react-icons/si";


const QueryEditor = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [dbTables, setDbTables] = useState([]);
  const [tableNames, setTableNames] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showColumnsModal, setShowColumnsModal] = useState(false);
  const [currentColumns, setCurrentColumns] = useState([]);

  const columns = useMemo(() => {
    // Dynamically create columns from results (assuming all objects have the same keys)
    const keys = results[0] ? Object.keys(results[0]) : [];
    return keys.map(key => ({
      Header: key,
      accessor: key,
    }));
  }, [results]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: results,
  });
  useEffect(() => {
    fetchTableNames()
  }, []);
useEffect(() => {
    function simulateNetworkRequest() {
      return new Promise((resolve) => setTimeout(resolve, 2000));
    }

    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const handleClick = () => setLoading(true);
  

  const fetchTableNames = async () => {
    try {
      const response = await axios.get('https://limited.angaafarm.com/get-db-tables');
      setTableNames(response.data.map(table => table.tableName));
    } catch (error) {
      console.error('Error fetching table names:', error);
    }
  };

  const fetchColumnNames = async (tableName) => {
    try {
      const response = await axios.get(`https://limited.angaafarm.com/get-table-columns?tableName=${tableName}`);
      setCurrentColumns(response.data.columns); // Assuming the endpoint returns an object with a `columns` array
      setShowColumnsModal(true);
    } catch (error) {
      console.error('Error fetching column names:', error);
      setCurrentColumns([]);
    }
  };
  
  const executeQuery = async () => {
    try {
      const response = await axios.post('https://limited.angaafarm.com/execute-query', { query });
      setResults(response.data);
    } catch (error) {
      console.error('Error executing query:', error);
      setResults([]);
    }
  };


  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [columns.map(col => col.Header)],
      body: results.map(row => columns.map(col => row[col.accessor])),
    });
    doc.save('query-results.pdf');
  };

   // Function to handle editor changes
   const handleEditorChange = (value, event) => {
    setQuery(value);
  };

  const handleButtonClick = () => {
    if (!isLoading) {
      handleClick();
      executeQuery();
    }
    // If isLoading is true, this will do nothing
  };
  return (
    <div style={{marginTop:15}}>
      <Container>
      <Row>

<Col sm={4}>
<Column/>
</Col>
<Col sm={8}>
<div>
<div className="editor-container">
  <Editor
    height="440px"
    defaultLanguage="sql"
    defaultValue="SELECT * FROM "
    value={query}
    onChange={handleEditorChange}
    theme="vs-dark"
    options={{
      wordWrap: 'on',
      minimap: { enabled: false },
    }}
  />
</div>

<Button
    variant="danger"
    size="lg"
    style={{marginTop:10}}
    className="custom-padding" // Apply the custom class

    disabled={isLoading}
    onClick={handleButtonClick}
  >
    {isLoading ? 'Loading…' : 'Execute Query'}
  </Button>

  {results.length > 0 ? (
    <>
      <Button variant="primary" size="sm" >
        <CSVLink
          data={results}
          filename="AngaaFarm.csv"
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          Download CSV <SiMicrosoftexcel />

        </CSVLink>
      </Button>

      <Table variant="dark"  {...getTableProps()} striped bordered hover size="sm" responsive>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  ) : (
    // Display a message prompting the user to execute a query if there are no results
    <div style={{ padding: '20px', textAlign: 'center', backgroundColor:'white',height:'83%',color:'red',borderRadius:'10px'}}>
      <h6>Execute a query on a table or apply a filter condition to display results.</h6>
    </div>
  )}
</div>
</Col>



      </Row>
   

</Container>


    </div>
  );
};

export default QueryEditor;