import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { BsShieldExclamation } from "react-icons/bs";

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const Register = () => {
  const navigate = useNavigate();

  // Use the same extended color theme as in the Login component
  const theme = {
    darkGreen: "#c74f43",
    mediumGreen: "#FBFADA",
    lightGreen: "#ADBC9F",
    offWhite: "#436850",
    font: {
      small: "0.8rem",
      medium: "0.9rem",
      large: "1.0rem",
    },
  };



  return (
    <Container style={{ marginTop: '100px', maxWidth: '400px' }}>
        <h2 style={{
            color: '#d1554b',
            marginBottom: '30px',
            padding: '10px',
          fontWeight:'bold',
            textAlign: 'center',
          }}>ANGAA MANAGEMENT </h2>

      <Row className="justify-content-center">
       <Col style={{
  backgroundColor: 'rgba(97, 147, 111, 0.5)', // Semi-transparent version of #61936f
  padding: '40px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(50px)', // Apply a blur effect
  webkitBackdropFilter: 'blur(10px)', // For Safari
}}>
              <h2 style={{
            color: 'white', 
            marginBottom: '30px', 
            padding:'10px',
            fontSize: theme.font.large,
            backgroundColor: theme.darkGreen,
            borderRadius: '10px',
            textAlign: 'center',
          }}>Change Password</h2>
          <Formik
            initialValues={{ email: ''}}
            validationSchema={RegisterSchema}
            onSubmit={(values, { setSubmitting }) => {
              const { confirmPassword, ...data } = values; // Exclude confirmPassword from the data sent to the server
              axios.post('https://pm.angaafarm.com/api/forgot-password', data)
                .then(response => {
                  alert(response.data); // Handle registration success
                  setSubmitting(false);
                  navigate('/login'); // Use the correct path for navigation
                })
                .catch(error => {
                  console.log('Error making request:', error);
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email"  placeholder="john@angaafarm.com" className="form-control" />
                  <ErrorMessage name="email" component="div" className="text-danger" />
                </div>
    
                <Button variant="primary" type="submit" disabled={isSubmitting} style={{ 
                  backgroundColor: theme.darkGreen, 
                  borderColor: theme.darkGreen,
                  ':hover': { backgroundColor: theme.mediumGreen },
                  fontSize: theme.font.medium,
                  marginTop: '20px', // Add some top margin to the button
                }}>
                  Send token
                </Button>
                <div className="mt-4" style={{ textAlign: 'center', fontSize: theme.font.small }}>
                  <Link to="/login" style={{ color: theme.darkGreen, marginRight: '10px' }}>Login</Link>
                  | 
                  <Link to="/register" style={{ color: theme.darkGreen, marginLeft: '10px' }}>Register</Link>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <div style={{marginTop:"100px",color:"#c74f43"}}>
      Login access is required to access content of this portal | If having issues with login in consult the admin  <BsShieldExclamation/>

      </div>
    </Container>
  );
};

export default Register;

