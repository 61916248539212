import React, { useEffect } from 'react';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logoImage from './Images/Logo2.jpg'; // Ensure the path is correct
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineQueryStats } from "react-icons/md";
import Weather from './Management/Weather'
import { GiFarmer } from "react-icons/gi";

const Topbar = () => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('userEmail');

  const logout = () => {
    localStorage.clear(); // Clears everything in localStorage

    localStorage.removeItem('userToken');
    localStorage.removeItem('expirationTime');
    window.location.href = '/login'
    // navigate('/login');
  };

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const expirationTime = new Date(localStorage.getItem('expirationTime'));

    if (!token || new Date() >= expirationTime) {
      navigate('/login');
    }
  }, [navigate]);

  const theme = {
    darkGreen: "#12372A",
    mediumGreen: "#FBFADA",
    lightGreen: "#ADBC9F",
    offWhite: "#436850",
    font: {
      small: "0.8rem",
      medium: "1rem",
      large: "1.5rem",
    },
  };

  const navigateToQuery = () => {
    navigate('/Query'); // Programmatically navigate to /Query
  };

  const navigateToWaether = () => {
    navigate('/Weather'); // Programmatically navigate to /Query
  };
  const NavigateToPlanting_Activity_Labour = () => {
    navigate('/Planting_Activity_Labour'); // Programmatically navigate to /Query
  };
  const Bed_Raking_Activity = () => {
    navigate('/Bed_Raking_Activity'); // Programmatically navigate to /Query
  };
  const Tomatoe_Harvest_Activity = () => {
    navigate('/Tomatoe_Harvest_Activity'); // Programmatically navigate to /Query
  };

  


  return (
    <Navbar style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', padding: '10px 20px', backgroundColor: '#163020', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }} expand="lg" variant="dark">
      <Navbar.Brand href="#home" style={{ fontWeight: 'bold', color: theme.offWhite }}>
        {/* Corrected the usage of the img tag */}
        <img src={logoImage} alt="Logo" style={{ height: '40px' }} />
      </Navbar.Brand>
      <div style={{marginLeft:'8%'}}>
      <Weather/>
      </div>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
    
  
        <Dropdown alignRight style={{marginRight:'10px'}}>
          <Dropdown.Toggle variant="success" id="dropdown-basic" >
            <GiFarmer  size={25} style={{ color:'white' }} />  Field Operations
          </Dropdown.Toggle>
          <Dropdown.Menu>
          {/* <Dropdown.Item disabled style={{ color: 'grey' }}>{userEmail}</Dropdown.Item> */}

            <Dropdown.Item onClick={NavigateToPlanting_Activity_Labour}>Planting Activities</Dropdown.Item>
            <Dropdown.Item  onClick={Tomatoe_Harvest_Activity}>Tomatoe Harvest Activities</Dropdown.Item>
            <Dropdown.Item onClick={Bed_Raking_Activity}>Bed Raking Activities</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>


        <Button className="me-2 mb-2" variant="danger" onClick={navigateToQuery} style={{ color: 'white', marginTop: '10px' }}>
        <MdOutlineQueryStats size={25} style={{ color:'white' }}/> Query Checker
        </Button>
        <span style={{ color: theme.offWhite }}></span>
        {localStorage.getItem('userEmail')?<Dropdown alignRight style={{marginRight:'10px'}}>
          <Dropdown.Toggle variant="outline-light" id="dropdown-basic" >
            <FaUserCircle size={25} style={{ color:'white' }} /> {userEmail}
          </Dropdown.Toggle>
          <Dropdown.Menu>
          {/* <Dropdown.Item disabled style={{ color: 'grey' }}>{userEmail}</Dropdown.Item> */}

            <Dropdown.Item onClick={logout} style={{ color: 'red' }}>Logout</Dropdown.Item>
            
          </Dropdown.Menu>
        </Dropdown>:<></>}
       
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Topbar;
