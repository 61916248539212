import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Accordion, Button } from 'react-bootstrap';
import './QueryEditor.css'; // Make sure to import your stylesheet

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [copySuccess, setCopySuccess] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://limited.angaafarm.com/get-db-tables');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(`\`${text}\``).then(() => {
      setCopySuccess(`Copied`);
      setTimeout(() => setCopySuccess(''), 2000); // Hide message after 2 seconds
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };
  return (
    <div>
      <h7>Table/Column</h7>
      <Table variant="dark"  bordered hover size="sm" className="compact-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Table Name {copySuccess && <h7 style={{color: 'red'}}>{copySuccess}</h7>}</th>
            <th>Columns</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} style={{backgroundColor:'#212529'}}>
              <td>{index + 1}</td>
              <button 
       variant="link"
        size="sm"
        onClick={() => copyToClipboard(item.tableName)}
        style={{ cursor: 'pointer' ,}}
      >
        {item.tableName}
      </button>

           
              <td>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>View Columns</Accordion.Header>
                    <Accordion.Body>
                      {item.columns.join(', ')}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponent;
