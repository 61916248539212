import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { IoMdLogIn } from "react-icons/io";
import { BsShieldExclamation } from "react-icons/bs";

import axios from 'axios';
// import Logo from './App/Images/Logo.png';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const Login = () => {
  const navigate = useNavigate();

  const theme = {
    darkGreen: "#c74f43",
    mediumGreen: "#FBFADA",
    lightGreen: "#ADBC9F",
    offWhite: "#436850",
    font: {
      small: "0.8rem",
      medium: "0.9rem",
      large: "1.0rem",
    },
  };

  const [showAlert, setShowAlert] = useState(false); // New state for alert visibility
  const [alertMessage, setAlertMessage] = useState(''); // New state for alert message
  const [alertVariant, setAlertVariant] = useState('success'); // New state for alert variant

  return (
    <Container style={{ marginTop: '100px', maxWidth: '400px' }}>
         <h2 style={{
            color: '#d1554b',
            marginBottom: '30px',
            padding: '10px',
          fontWeight:'bold',
            textAlign: 'center',
          }}>ANGAA MANAGEMENT </h2>

      <Row className="justify-content-center">
      <Col style={{
  backgroundColor: 'rgba(97, 147, 111, 0.5)', // Semi-transparent version of #61936f
  padding: '40px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(50px)', // Apply a blur effect
  webkitBackdropFilter: 'blur(10px)', // For Safari
}}>


             <h2 style={{
            color: 'white',
            marginBottom: '30px',
            padding: '10px',
            fontSize: theme.font.large,
            backgroundColor: theme.darkGreen,
            borderRadius: '10px',
            textAlign: 'center',
          }}>Agronomist Login</h2>
          
          {showAlert && <Alert variant={alertVariant}>{alertMessage}</Alert>} {/* Display the alert based on state */}
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              axios.post('https://pm.angaafarm.com/api/login', values)
                .then(response => {
                  // Set the alert message and show it
                  setAlertMessage('Login successful! Redirecting...');
                  setAlertVariant('success');
                  setShowAlert(true);
                  localStorage.setItem('userEmail', values.email);
                  // Simulate redirect delay to show message
                  setTimeout(() => {
                    // navigate('/');
                    window.location.href = '/Planting_Activity_Labour'; // This navigates to the home page and refreshes the page

                  }, 2000);

                  const now = new Date();
                  // const expirationTime = new Date(now.getTime() + 30*60000);
                  const expirationTime = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours in milliseconds

                  localStorage.setItem('userToken', response.data);
                  localStorage.setItem('expirationTime', expirationTime.toISOString());
                })
                .catch(error => {
                  console.log(error);
                  // Set the alert message for error and show it
                  setAlertMessage('Failed to login. Please check your credentials.');
                  setAlertVariant('danger');
                  setShowAlert(true);
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group mb-4">
                  <label htmlFor="email" style={{ fontSize: theme.font.medium }}>Email</label>
                  <Field type="email" name="email" placeholder="john@angaafarm.com" className="form-control" style={{ 
                    borderColor: theme.mediumGreen, 
                    ':focus': { boxShadow: `0 0 0 0.2rem ${theme.lightGreen}` } 
                  }} />
                  <ErrorMessage name="email" component="div" className="text-danger" />
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="password" style={{ fontSize: theme.font.medium }}>Password</label>
                  <Field type="password" name="password" placeholder="********"  className="form-control" style={{ 
                    borderColor: theme.mediumGreen,
                    ':focus': { boxShadow: `0 0 0 0.2rem ${theme.lightGreen}` } 
                  }} />
                  <ErrorMessage name="password" component="div" className="text-danger" />
                </div>
                <Button variant="primary" type="submit" disabled={isSubmitting} style={{ 
                  backgroundColor: theme.darkGreen, 
                  borderColor: theme.darkGreen,
                  ':hover': { backgroundColor: theme.mediumGreen },
                  fontSize: theme.font.medium
                }}>
                  <IoMdLogIn />  

                  Sign In
                </Button>
                
                <div className="mt-4" style={{ textAlign: 'center', fontSize: theme.font.small }}>
                  <Link to="/register" style={{ color: theme.darkGreen, marginRight: '10px' }}>Register</Link>
                  | 
                  <Link to="/password-reset" style={{ color: theme.darkGreen, marginLeft: '10px' }}>Forgot Password?</Link>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <div style={{marginTop:"100px",color:"#c74f43"}}>
      Login access is required to access content of this portal | If having issues with login in consult the admin  <BsShieldExclamation/>

      </div>
    </Container>
  );
};

export default Login;
