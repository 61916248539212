import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Login from './App/Login';
import Register from './App/Signup';
import PasswordReset from './App/PasswordReset';
import Sidebar from './App/Sidebar'; // Create Sidebar component
import Topbar from './App/Topbar'; // Create Topbar component
import Farm_Activity from './App/Management/Farm_Activity';
import Farm_Block from './App/Management/Farm_Block';
import Farm_Crop from './App/Management/Farm_Crop';
import Farm_Detail from './App/Management/Farm_Detail';
import Farm_Employee from './App/Management/Farm_Employee';
import Planting_Activity_Labour from './App/Management/Planting_Activity_Labour';
import Tools from './App/Management/Tools';
import Farm_Fertililizer_Type from './App/Management/Farm_Fertililizer_Type';
import Bed_Raking_Activity from './App/Management/Bed_Raking_Activity';
import Tomatoe_Harvest_Activity from './App/Management/Tomatoe_Harvest_Activity';
import Farm_Section from './App/Management/Farm_Section';
import Background from './App/Images/Background2.png';
import Tomatoe from './App/Images/Tomatoe-min.png';
import Querry from './App/Editor/App';
import './App.css'
function App() {
  return (
    <Router>
      <div className="App">
        {localStorage.getItem('userEmail')?<Topbar />:<></>}
        
       


        <div style={{ backgroundColor: "#EEF0E5", backgroundImage: `url(${Background})`, 
      backgroundSize: 'cover', // Cover the entire page
      backgroundPosition: 'center center', // Center the image
      backgroundRepeat: 'no-repeat', // Do not repeat the image
      width: '100vw', // Full view width
      height: '100vh', // Full view height
      // position: 'absolute', // Use absolute positioning
      top: 0, // Start from the top of the page
      left: 0, // Start from the left of the page
      }}>
          <Container fluid>
            <Row>
              <Col md={2} style={{ paddingRight: 0 }}>
              {/* {localStorage.getItem('userEmail') ? <Sidebar /> : <img src={Tomatoe} alt="Tomato" style={{width:'500px'}}/>} */}
              {localStorage.getItem('userEmail') ? <Sidebar /> : <img src={Tomatoe} alt="Tomato" className="fade-img"/>}

              </Col>
              <Col md={10} style={{ paddingLeft: 0 }}>
                <div style={{ paddingLeft: '1px' }}>
                  <Routes>
                  {localStorage.getItem('userEmail') ? <Route path="/" element={<Navigate replace to="/Planting_Activity_Labour" />} />:<Route path="/login" element={<Login />} />}
                  {localStorage.getItem('userEmail') ? <Route path="/" element={<Navigate replace to="/Planting_Activity_Labour" />} />:<Route path="/register" element={<Register />} />}
                  {localStorage.getItem('userEmail') ? <Route path="/" element={<Navigate replace to="/Planting_Activity_Labour" />} />:<Route path="/password-reset" element={<PasswordReset />} />}
                    <Route path="/Farm_Activity" element={<Farm_Activity />} />
                    <Route path="/Farm_Block" element={<Farm_Block />} />
                    <Route path="/Farm_Crop" element={<Farm_Crop />} />
                    <Route path="/Farm_Detail" element={<Farm_Detail />} />
                    <Route path="/Farm_Employee" element={<Farm_Employee />} />
                    <Route path="/Farm_Fertililizer_Type" element={<Farm_Fertililizer_Type />} />
                    <Route path="/Query" element={<Querry />} />
                    <Route path="/Bed_Raking_Activity" element={<Bed_Raking_Activity />} />
                    <Route path="/Tomatoe_Harvest_Activity" element={<Tomatoe_Harvest_Activity />} />
                    <Route path="/Farm_Section" element={<Farm_Section />} />
                    <Route path="/Planting_Activity_Labour" element={<Planting_Activity_Labour />} />
                    <Route path="/Tools" element={<Tools />} />
                    {localStorage.getItem('userEmail') ? <Route path="/" element={<Navigate replace to="/Planting_Activity_Labour" />} />:<Route path="/" element={<Navigate replace to="/login" />} />}
                  </Routes>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
  
      </div>
    </Router>
  );
}

export default App;
